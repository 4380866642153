/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Subtitle, Text } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Menu Karlín"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="css-f0n5sc --style2 --full pb--60 pt--60" name={"h0zyvo88cq"} parallax={false} fullscreen={true} css={css`
      
    background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/18947/c21f75dbe2d941f19e7ffb38133abb2f_s=350x_.jpeg);
    @media (min-width: 351px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/18947/c21f75dbe2d941f19e7ffb38133abb2f_s=660x_.jpeg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/18947/c21f75dbe2d941f19e7ffb38133abb2f_s=860x_.jpeg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/18947/c21f75dbe2d941f19e7ffb38133abb2f_s=1400x_.jpeg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/18947/c21f75dbe2d941f19e7ffb38133abb2f_s=2000x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/18947/c21f75dbe2d941f19e7ffb38133abb2f_s=660x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/18947/c21f75dbe2d941f19e7ffb38133abb2f_s=1400x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/18947/c21f75dbe2d941f19e7ffb38133abb2f_s=3000x_.jpeg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--102 title-box--invert" content={"Menu - Karlín"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"wx0zls4fs7"}>
          
          <ColumnWrap className="column__flex el--3" columns={"3"}>
            
            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Title className="title-box fs--48" content={"Horké nápoje"}>
              </Title>

              <Subtitle className="subtitle-box" content={"<span style=\"font-weight: bold;\">Káva</span>"}>
              </Subtitle>

              <Text className="text-box fs--14 mt--0" content={"Espresso - 55 Kč<br>Double Espresso - 79 Kč<br>Espresso Macchiato - 59 Kč<br>Cappuccino - 75 Kč<br>Flat White - 90 Kč<br>Latte - 85 Kč<br>Americano - 79 Kč<br>"}>
              </Text>

              <Subtitle className="subtitle-box" content={"<span style=\"font-weight: bold;\">Kávové speciality</span>"}>
              </Subtitle>

              <Text className="text-box fs--14 mt--0" content={"Iced Latte - 85 Kč<br>Affogato - 90 Kč<br>Espresso &amp; Tonic - 95 Kč<br>Latte s příchutí - 89 Kč<br>"}>
              </Text>

              <Title className="title-box fs--48 mt--80" content={"Ostatní nápoje"}>
              </Title>

              <Subtitle className="subtitle-box" content={"<span style=\"font-weight: bold;\">Nealko nápoje</span>"}>
              </Subtitle>

              <Text className="text-box fs--14 mt--0" content={"Voda - 49 Kč<br>Minerální voda &nbsp;- 49 Kč<br>Domácí limonády - 75 Kč<br>Cola (classic/slim) - 60 Kč<br>Tonic - 60 Kč<br>"}>
              </Text>

              <Subtitle className="subtitle-box" content={"<span style=\"font-weight: bold;\">Čerstvé džusy</span>"}>
              </Subtitle>

              <Text className="text-box fs--14 mt--0" content={"Pomerančový (0,3l) - 89 Kč<br>Grapefruitový (0,3l) - 89 Kč<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Title className="title-box" content={""}>
              </Title>

              <Subtitle className="subtitle-box" content={"<span style=\"font-weight: 700;\">Čaje sypané/ čerstvé</span><br>"}>
              </Subtitle>

              <Text className="text-box fs--14 mt--0" content={"Indian Chai Latte - 85 Kč<br>Čerstvý mátový - 75 Kč<br>Čerstvý zázvorový - 75 Kč<br>Sypaný čaj - 75 Kč<br>Matcha Latte - 90 Kč<br>"}>
              </Text>

              <Subtitle className="subtitle-box" content={"<span style=\"font-weight: bold;\">Další horké nápoje</span><br>"}>
              </Subtitle>

              <Text className="text-box fs--14 mt--0" content={"Horká čokoláda - 85 Kč\nOliver's Cocoa s Marshmallow - 85 Kč<br>"}>
              </Text>

              <Subtitle className="subtitle-box" content={"<br>"}>
              </Subtitle>

              <Text className="text-box fs--14 mt--0" content={"<br>"}>
              </Text>

              <Title className="title-box mt--50" content={""}>
              </Title>

              <Subtitle className="subtitle-box" content={"<span style=\"font-weight: bold;\">Víno</span>"}>
              </Subtitle>

              <Text className="text-box fs--14 mt--0" content={"Rosé Svatovavřinecké (0,15l/0,75l) - 105 Kč / 425 Kč<br>Riesling Meyer&nbsp;(0,15l/0,75l) - 105 Kč / 425 Kč<br>Montepulciano Moda&nbsp;(0,15l/0,75l) - 105 Kč / 425 Kč<br>Terra Vizina Prossecco (0,15l/0,75l) - 105 Kč / 425 Kč<br>"}>
              </Text>

              <Subtitle className="subtitle-box" content={"<span style=\"font-weight: bold;\">Míchané nápoje</span><br>"}>
              </Subtitle>

              <Text className="text-box fs--14 mt--0" content={"Aperol Spritz - 125 Kč<br>Gin Tonic - 125 Kč<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--shape4" style={{"maxWidth":800,"backgroundColor":"rgba(112,156,159,1)"}}>
              
              <Title className="title-box fs--48 pl--06" content={"<span style=\"color: rgb(255, 255, 255);\">Bistro</span><br>"}>
              </Title>

              <Text className="text-box fs--14 pl--25 pr--25" content={"<span style=\"color: rgb(255, 255, 255);\">Croissant šunka/sýr&nbsp;- 79 Kč<br><br>Croissant mozzarella&nbsp;- 85 Kč<br><br>Croissant mozzarella/prosciutto&nbsp;- 85 Kč<br><br>Obložený chlebík&nbsp;- 85 Kč<br><br><br><br>Chia kelímek - 65 Kč<br><br>Výběr domácích dortů - od 99 Kč<br>i bezlepkových<br><br>Domácí koláče - 59 Kč<br><br>Banana bread - 65 Kč<br></span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}